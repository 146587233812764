import { Box, Center, Card, Image, Text, SimpleGrid, Space } from '@mantine/core';
import { motion, useAnimation } from "framer-motion"
import Header from '../components/header';

const eventChoiceHover = {
  bgpic: {
    init: {
      scale: 1,
    },
    hover: {
      scale: 1.1,
    },
  }
}

const EventOptionCard = ({
  title,
  subtitle,
  href,
  imgSrc,
}) => {
  const controls = useAnimation()
  const over = () => {
    controls.start('hover')
  }
  const out = () => {
    controls.start('init')
  }
  return <Card
    onMouseOver={over}
    onMouseOut={out}
    shadow="sm"
    p="xl"
    component="a"
    href={href}
    sx={(theme) => ({
      background: theme.colors.blue[0]
    })}
  >
    <Card.Section>
      <motion.img
        variants={eventChoiceHover.bgpic}
        initial="init"
        animate={controls}
        src={imgSrc}
        style={{ height: '40vh', width: '100%', objectFit: 'cover' }}
      />
      <Center style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0, left: 0,
        background: 'rgba(0,0,0,0.8)',
      }}>
        <div style={{
          width: '70%',
          margin: 'auto',
          textAlign: 'center',
        }}>
          <Text
            component="span"
            align="center"
            variant="gradient"
            gradient={{ from: 'pink', to: 'purple', deg: 45 }}
            weight={700}
            style={{ fontSize: '150%', fontFamily: 'Greycliff CF, sans-serif' }}
          >
            {title}
          </Text>
          <Text size="sm">{subtitle}</Text>
        </div>
      </Center>
    </Card.Section>

  </Card>
}

const Index = () => <Box sx={(theme) => ({
  padding: `${theme.spacing.md}px 0`
})}>
    <Header>Events</Header>

    <SimpleGrid
      cols={2}
      spacing="lg"
      breakpoints={[
        { maxWidth: 'md', cols: 2, spacing: 'md' },
        { maxWidth: 'sm', cols: 2, spacing: 'sm' },
        { maxWidth: 'xs', cols: 2, spacing: 'sm' },
      ]}
    >
      <EventOptionCard
        title="Face to Face"
        imgSrc={'/face2face.webp'}
        href="/events?offline"
      />
      <EventOptionCard
        title="Online"
        imgSrc={'/online.webp'}
        href="/events?online"
      />
    </SimpleGrid>

    <Space h="md" />


  <Card
      shadow="sm"
      p="xl"
    >
      <Card.Section>
        <Image src="/hero.webp" height={'60vh'} />
        <Center style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          top: 0, left: 0,
          background: 'rgba(0,0,0,0.8)',
        }}>
          <div style={{
            width: '70%',
            margin: 'auto',
            textAlign: 'center',
          }}>
          <Text
            component="span"
            align="center"
            variant="gradient"
            gradient={{ from: 'red', to: 'yellow', deg: 45 }}
            weight={700}
            style={{ fontSize: '190%', fontFamily: 'Greycliff CF, sans-serif' }}
          >
            Community. Cohesion. Care.
          </Text>

          <Text size="md" style={{ color: '#FFF' }}>
          Fireworks aims to bridge the gap between student volunteers and the communities that need them most, providing meaningful experiences and opportunities to all!
          </Text>
          </div>
        </Center>
      </Card.Section>

    </Card>

</Box>
export default Index;