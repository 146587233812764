import { Checkbox, Image, Button, Box, Input, InputWrapper, MediaQuery, MultiSelect, Select, Space, Text, Textarea, Title, Container } from "@mantine/core";
import { Dropzone } from '@mantine/dropzone';
import { At, BrandFacebook, BrandInstagram, BrandTwitter, Photo, Upload } from 'tabler-icons-react';
import dropzoneChildren, {onUpload} from './dropzoneChildren';

const Role = [
  'STUDENT',
  'TEACHER',
  'ORGANIZATION',
  'ADMIN',
];

const getYears = () => {
  const years = [];
  for (let i = 2022; i <= new Date().getFullYear() + 15; i++) {
    years.push({ label: i, value: ""+i });
  }
  return [{ label: 'N/A', value: null }, ...years];
}

const UsersEditView = ({
  schools,
  organizations,
  editUserObject,
  setEditUserObject,
  handleEditSubmit,
  resourcesUpdated,
}) => {
  return <Container sx={(theme) => ({
    marginTop: theme.spacing.md,
  })}>
  <div style={{ borderRadius: 3, backgroundColor: '#f6f6f6', padding: 10 }}>
    <Box>
      <Title order={1} sx={(theme) => ({
        color: theme.colors.green[5],
      })}>{editUserObject?.id && 'Edit' || 'Create'} User's Information</Title>

      <Space h="xl" />

      <InputWrapper
        id="input-demo"
        label="First Name"
        required
      >
        <Input
          placeholder="First name"
          rightSectionWidth={70}
          defaultValue={editUserObject?.first_name}
          onChange={(e) => setEditUserObject({ ...editUserObject, first_name: e.target.value })}
          styles={{ rightSection: { pointerEvents: 'none' } }}
        />
      </InputWrapper>

      <Space h="md" />

      <InputWrapper
        id="input-demo"
        label="Last Name"
        required
      >
        <Input
          placeholder="Last name"
          rightSectionWidth={70}
          defaultValue={editUserObject?.last_name}
          onChange={(e) => setEditUserObject({ ...editUserObject, last_name: e.target.value })}
          styles={{ rightSection: { pointerEvents: 'none' } }}
        />
      </InputWrapper>

      <Space h="md" />

      <InputWrapper
        id="input-demo"
        label="Pronouns"
        required
      >
        <Input
          placeholder="He/She"
          rightSectionWidth={70}
          defaultValue={editUserObject?.pronouns}
          onChange={(e) => setEditUserObject({ ...editUserObject, pronouns: e.target.value })}
          styles={{ rightSection: { pointerEvents: 'none' } }}
        />
      </InputWrapper>

      <Space h="md" />

      <InputWrapper
        id="input-demo"
        label="Please upload a photo of the user"
        required
      >
        <Dropzone
            multiple={false}
            onDrop={(files) => onUpload(files).then(url => setEditUserObject({ ...editUserObject, picture: url }))}
            onReject={(files) => console.log('rejected files', files)}
            maxSize={3 * 1024 ** 2}
            accept={['image/png', 'image/jpeg', 'image/gif', 'image/webp']}>
            {(status) => dropzoneChildren(<div style={{ width: 200, marginLeft: 'auto', marginRight: 'auto' }}>
            <Image
              src={editUserObject?.picture}
              imageProps={{ referrerpolicy: "no-referrer" }}
            />
          </div>, status)}
          </Dropzone>
      </InputWrapper>

      <Space h="md" />

      <InputWrapper
        id="input-demo"
        label="Role"
      >
        <MediaQuery
          query="(max-width: 800px)"
          styles={{ width: '100%' }}
        >
          <MultiSelect
            data={Role}
            limit={30}
            searchable
            nothingFound="Nothing found"
            placeholder="Role"
            value={editUserObject?.role}
            style={{ flex: 1 }}
            onChange={(e) => setEditUserObject({ ...editUserObject, role: e })}
            sx={(theme) => ({
              backgroundColor: theme.colors.navy[2],
            })}
          />
        </MediaQuery>
      </InputWrapper>

      {/* role_confirmed : boolean use checkbox */}
      <Space h="md" />
      <InputWrapper
        label="Role Confirmed"
      >
        <Checkbox
          checked={editUserObject?.role_confirmed}
          onChange={(e) => setEditUserObject({ ...editUserObject, role_confirmed: e.target.checked })}
        />
      </InputWrapper>

      <Space h="md" />
        <InputWrapper
          label="School Year"
        >
          <Select
            placeholder="Pick one"
            value={editUserObject?.student_graduation_year}
            onChange={(e) => setEditUserObject({ ...editUserObject, student_graduation_year: e })}
            data={getYears()}
          />
        </InputWrapper>

      <Space h="md" />

        <InputWrapper
          id="input-demo"
          required
          label="Organization"
        >
          <Select
            placeholder="User's organization"
            nothingFound="No options"
            maxDropdownHeight={280}
            value={editUserObject?.organization_id}
            onChange={(e) => setEditUserObject({ ...editUserObject, organization_id: e })}
            data={[{ label: 'N/A', value: null }, ...organizations?.map(s => ({ label: s.name, value: s.id }))]}
          />
        </InputWrapper>

      <Space h="md" />

        <InputWrapper
          id="input-demo"
          required
          label="School"
        >
          <Select
            placeholder="User's school"
            nothingFound="No options"
            maxDropdownHeight={280}
            value={editUserObject?.school_id}
            onChange={(e) => setEditUserObject({ ...editUserObject, school_id: e })}
            data={[{ label: 'N/A', value: null }, ...schools?.map(s => ({ label: s.name, value: s.id }))]}
          />
        </InputWrapper>

      <Space h="md" />

      <InputWrapper
        id="input-demo"
        label="Email"
        required
      >
        <Input
          icon={<At size={16} />}
          placeholder="Email"
          defaultValue={editUserObject?.email}
          rightSectionWidth={70}
          onChange={(e) => setEditUserObject({ ...editUserObject, email: e.target.value })}
          styles={{ rightSection: { pointerEvents: 'none' } }}
        />
      </InputWrapper>

      <Space h="md" />

      <InputWrapper
        id="input-demo"
        label="Twitter"
      >
        <Input
          icon={<BrandTwitter size={16} />}
          placeholder="Twitter"
          defaultValue={editUserObject?.twitter}
          rightSectionWidth={70}
          onChange={(e) => setEditUserObject({ ...editUserObject, twitter: e.target.value })}
          styles={{ rightSection: { pointerEvents: 'none' } }}
        />
      </InputWrapper>
      <Space h="md" />

      <InputWrapper
        id="input-demo"
        label="Instagram"
      >
        <Input
          icon={<BrandInstagram size={16} />}
          placeholder="Instagram"
          defaultValue={editUserObject?.instagram}
          rightSectionWidth={70}
          onChange={(e) => setEditUserObject({ ...editUserObject, instagram: e.target.value })}
          styles={{ rightSection: { pointerEvents: 'none' } }}
        />
      </InputWrapper>
      <Space h="md" />

      <InputWrapper
        id="input-demo"
        label="Facebook"
      >
        <Input
          icon={<BrandFacebook size={16} />}
          placeholder="Facebook"
          defaultValue={editUserObject?.facebook}
          rightSectionWidth={70}
          onChange={(e) => setEditUserObject({ ...editUserObject, facebook: e.target.value })}
          styles={{ rightSection: { pointerEvents: 'none' } }}
        />
      </InputWrapper>

      <Space h="md" />

      <Textarea
        placeholder="Under 300 words"
        defaultValue={editUserObject?.about}
        onChange={(e) => setEditUserObject({ ...editUserObject, about: e.target.value })}
        label="About the user"
      />

      <Space h="md" />

      <Button
        onClick={handleEditSubmit}
        sx={{
          width: '100%',
          backgroundColor: 'green[5]',
          color: 'white',
          borderRadius: 3,
          '&:hover': {
            backgroundColor: 'green[4]',
          },
        }}
      >{resourcesUpdated && 'Saved' || 'Save'}</Button>
    </Box>
  </div></Container>
}

export default UsersEditView; 