import { useState, useEffect } from 'react';
import axios from '../api';

export default function useCURDStore({
  resourcesName,
  fields,
}) {
  const [resources, setResources] = useState([]);
  const [resource, setResource] = useState({});
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [resourcesUpdated, setResourcesUpdated] = useState(false);

  useEffect(() => {
    listResources({
      search,
      page: 1,
    });
    setPage(1);
  }, [resourcesName])

  async function reloadResources() {
    await listResources({ _page: page, _search: search });
  }

  async function listResources({
    _page,
    _search,
  }) {
    setLoading(true);
    setPage(_page);
    setSearch(_search);
    const { data } = await axios().get(`/${resourcesName}`, {params: { page: _page, search: _search }});
    setResources(data.data);
    setTotalPage(data.totalPage);
    setLoading(false);
  }

  async function getResource(id) {
    setLoading(true);
    const { data } = await axios().get(`/${resourcesName}/${id}`);
    setResource(data);
    setLoading(false);
  }

  async function createResource(resource) {
    setLoading(true);
    const { data } = await axios().post(`/${resourcesName}`, resource);
    setResource(data);
    setLoading(false);
    setResourcesUpdated(true);
    setTimeout(() => {
      setResourcesUpdated(false);
    }, 3000);
    return data.id
  }

  async function updateResource(id, resource) {
    setLoading(true);
    const { data } = await axios().post(`/${resourcesName}/${id}`, resource);
    setResource(data);
    setLoading(false);
    setResourcesUpdated(true);
    await listResources({ _page: page, _search: search });
    setTimeout(() => {
      setResourcesUpdated(false);
    }, 3000);
  }

  async function deleteResource(id) {
    setLoading(true);
    await axios().delete(`/${resourcesName}/${id}`);
    setLoading(false);
    await listResources({ _page: page, _search: search });
  }

  return {
    page,
    totalPage,
    loading,
    resource,
    resources,
    resourcesUpdated,
    listResources,
    getResource,
    createResource,
    updateResource,
    deleteResource,
    reloadResources,
  }
}