import { Box, Container, Text, Paper, Space, Button } from "@mantine/core";
import { useState, useEffect } from "react";
import useUserStore from '../hooks/useUserStore';
import api from "../api";
import { Link, useNavigate } from "react-router-dom";
import Event from "../components/event";
import Header from "../components/header";
import { CalendarEvent } from "tabler-icons-react";

const MyEvents = () => {
  const [events, setEvents] = useState([]);
  const [pastEvents, setPostEvents] = useState([]);
  const [user, fetchUser] = useUserStore();
  useEffect(() => {
    const fetchEvents = async () => {
      setEvents((await api().get(`events/?user_id=${user.id}`)).data.data);
      setPostEvents((await api().get(`events/?user_id=${user.id}&past_event=true`)).data.data);
    };
    fetchEvents();
  }, [user])
  const navigate = useNavigate();
  return <Container sx={(theme) => ({
    marginTop: theme.spacing.md,
  })}>
    <Box>
      <Button leftIcon={<CalendarEvent />} onClick={() => navigate(`/events`)} variant="gradient" gradient={{ from: 'teal', to: 'lime', deg: 105 }} size="md">Events</Button>
      <br />
      <Space h="md" />
      <Header>
        My Upcoming Events
      </Header>
      {
        events.map((event) => (<Event key={event.id} {...event} />))}
      { events.length === 0 ? <Paper shadow="sm" radius="md" p="xl" sx={(theme) => ({
          backgroundColor: theme.colors.navy[2],
        })}>
          <Text>No event yet, explore and join one in <Text
            component={Link}
            to={'/events'}
            variant="gradient"
            gradient={{ from: 'lime', to: 'yellow', deg: 45 }}
            weight={700}
          >Event</Text> now</Text>
        </Paper>
      : <></>}
    </Box>
    <Space h='md' />
    <Box>
      <Header>
        My Past Events
      </Header>
      {pastEvents.map((event) => (<Event key={event.id} {...event} />))}
      {pastEvents.length === 0 ? <Paper shadow="sm" radius="md" p="xl" sx={(theme) => ({
          backgroundColor: theme.colors.navy[2],
        })}>
          <Text>No event yet.</Text>
        </Paper>: <></>}
    </Box>
    <Space h='md' />
</Container>
}
export default MyEvents