import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useGlobalState, setGlobalState } from '../store';
import axios from '../api';


export default function useUserStore() {
  const navigate = useNavigate();
  const [user] = useGlobalState('user');
  async function loadUser() {
    if (Object.keys(user).length === 0) {
      await fetchUser();
    }
  }
  async function fetchUser() {
    console.log('fetching user')
    try {
      const latestUser = (await axios().get('/users/me'))?.data;
      const login = JSON.parse(localStorage.getItem('login'));
      login.user = latestUser;
      localStorage.setItem('login', JSON.stringify(login));
      setGlobalState('user', latestUser);
    } catch (e) {
      localStorage.removeItem('login');
      navigate('/login');
    }
  }
  useEffect(() => {
    loadUser();
  }, [])
  return [user, fetchUser];
}
