import { Container, Center } from '@mantine/core';
import { useEffect, useState } from 'react';
import Event from '../components/event';
import EventFilterToolBar from '../components/event_filter_tool_bar';
import { useLocation } from "react-router-dom";
import queryString from 'query-string';
import api from '../api';

const Events = () => {
  let location = useLocation();
  const [events, setEvents] = useState([])
  const [query, setQuery] = useState({});

  useEffect(() => {
    const q = { disabled: false }
    if (location.search.includes('online')) {
      q.online = true;
    } else if (location.search.includes('offline')) {
      q.offline = true;
    }
    setQuery(q)
    const fetchEvents = async () => {
      setEvents((await api().get(`events?${queryString.stringify(q, { arrayFormat: 'bracket' })}`))?.data?.data)
    }
    fetchEvents();
  }, [])

  const modeChangeHandler = async (newState) => {
    const q = { ...query };
    if (newState.includes('online') && !newState.includes('offline')) {
      q.online = true;
      delete q.offline;
    } else if (newState.includes('offline') && !newState.includes('online')) {
      delete q.online;
      q.offline = true;
    } else if (newState.includes('online') && newState.includes('offline')) {
      q.online = true;
      q.offline = true;
    } else if (!newState.includes('online') && !newState.includes('offline')) {
      delete q.online;
      delete q.offline;
    }
    setQuery(q)
    setEvents((await api().get(`events?${queryString.stringify(q, {arrayFormat: 'bracket'})}`)).data.data)
  }

  const followOnlyHandler = async (newState) => {
    const q = { ...query };
    if (newState) {
      q.followed_only = true;
    } else {
      delete q.followed_only;
    }
    setQuery(q)
    setEvents((await api().get(`events?${queryString.stringify(q, {arrayFormat: 'bracket'})}`)).data.data)
  }

  const searchHandler = async (search) => {
    const q = { ...query, search };
    setQuery(q);
    setEvents((await api().get(`events?${queryString.stringify(q, {arrayFormat: 'bracket'})}`)).data.data)
  }

  const serviceTagsFilterHandler = async (tags) => {
    const q = { ...query, badges: tags };
    setQuery(q);
    setEvents((await api().get(`events?${queryString.stringify(q, {arrayFormat: 'bracket'})}`)).data.data)
  }

  return (<Container sx={(theme) => ({
    marginTop: theme.spacing.md,
  })}>
    <EventFilterToolBar
      eventModeHandler={modeChangeHandler}
      serviceTagsFilterHandler={serviceTagsFilterHandler}
      searchHandler={searchHandler}
      followOnlyHandler={followOnlyHandler}
      isOnline={location.search.includes('online')}
      isOffline={location.search.includes('offline')}
      followOnly={!!query.followed_only}
    />
    <Center style={{ width: '100%', height: 'auto' }}>
    <div>{events.map(event => (<Event {... event}/>))}</div>
    </Center>
  </Container>)
}

export default Events