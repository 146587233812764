import {
  Chip,
  Chips,
  Group,
  MediaQuery,
  TextInput,
  Button,
  MultiSelect,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { Search } from 'tabler-icons-react';

const ServiceTags = [
  { label: 'Education', value: 'Education' },
  { label: 'Community', value: 'Community' },
  { label: 'Social', value: 'Social' },
  { label: 'Medical & Integrated Health', value: 'Medical & Integrated Health' },
  { label: 'Rehabilitation', value: 'Rehabilitation' },
  { label: 'Pre-school', value: 'Pre-school' },
];

const EventFilterToolBar = ({
  eventModeHandler,
  searchHandler,
  followOnlyHandler,
  serviceTagsFilterHandler,
  isOnline,
  isOffline,
  followOnly,
}) => {
  const [searchInput, setSearchInput] = useState('');
  const x = [];
  useEffect(() => {
    if (!!isOnline) { x.push('online'); }
    if (!!isOffline) { x.push('offline'); }
  }, [])
  return (
    <Group
    position="right"
    sx={(theme) => ({
      marginBottom: theme.spacing.md,
    })}
    >
      <MediaQuery
        query="(max-width: 800px)"
        styles={{ width: '100%' }}
      ><>
        <Chips position="center" multiple defaultValue={x} onChange={eventModeHandler}>
          <Chip value="online">Online</Chip>
          <Chip value="offline">Face to face</Chip>
        </Chips>
        <Chip value="chip" checked={followOnly} onChange={followOnlyHandler}>
          My following
        </Chip></>
      </MediaQuery>
      <MediaQuery
      query="(max-width: 800px)"
      styles={{ width: '100%' }}
    >
      <MultiSelect
        data={ServiceTags}
        limit={30}
        searchable
        nothingFound="Nothing found"
        placeholder="Type of Services"
        style={{ flex: 1 }}
        sx={(theme) => ({
          backgroundColor: theme.colors.navy[2],
        })}
        onChange={serviceTagsFilterHandler}
      />
    </MediaQuery>
      <MediaQuery
        query="(max-width: 800px)"
        styles={{ width: '100%' }}
      >
        <TextInput
          placeholder="Search" rightSection={<Button onClick={() => searchHandler(searchInput)} color="blue" size="xxs"> <Search size={14} /> </Button>}
          sx={(theme) => ({
            backgroundColor: theme.colors.navy[2],
          })}
          onChange={(v) => setSearchInput(v.target.value)}
        />
      </MediaQuery>
    </Group>
  )
}

export default EventFilterToolBar;