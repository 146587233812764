import { Card, Image, Text, Button, Group, Title, Divider } from '@mantine/core';
import { Dots, CalendarTime } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const Event = ({
  id,
  pic_url,
  name,
  from_date,
  to_date,
  description
}) => {
  const navigate = useNavigate();
  return <Card shadow="sm" p="lg" style={{ marginBottom: 40 }} sx={(theme) => ({
        backgroundColor: theme.colors.white[0],
      })}>
        <Card.Section>
          <Image
            src={pic_url}
            height={360}
          />
        </Card.Section>
        <Title order={1} sx={(theme) => ({
          margin: `${theme.spacing.lg}px 0`,
        })}>{name}</Title>
        <Group spacing="xs">
          <CalendarTime size={30} />
          <Text weight={500} size="md">
            {dayjs(from_date).format('DD MMMM YYYY')} - {dayjs(to_date).format('DD MMMM YYYY')}
          </Text>

        </Group>
        <Divider my="sm" />
        <Text>{description}</Text>
        <Button
          variant="filled"
          color="blue"
          fullWidth
          style={{ marginTop: 14 }}
          leftIcon={<Dots size={25} />}
          onClick={() => {navigate(`/events/${id}`) }}
        >
          Details
        </Button>
  </Card>
}

export default Event;