import { Button, Popover } from "@mantine/core";
import React, { useState } from "react";

const PopoverButton = ({
  buttonName,
  children,
  buttonProps,
  props,
}) => {
  const [opened, setOpened] = useState(false)
  return <Popover
    opened={opened}
    onClose={() => setOpened(false)}
    target={<Button {...buttonProps} onClick={() => setOpened((o) => !o)}>{buttonName}</Button>}
    width={260}
    position="bottom"
    withArrow
    radius="xl"
    spacing="xl"
    shadow="xl"
    {...props}
  >
    {children}
  </Popover>
}

export default PopoverButton;