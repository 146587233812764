import EventEditView from '../components/eventEditView';
import useCURDStore from '../hooks/useCURDStore';
import { useState, useEffect } from 'react';
import { useMatch } from 'react-router-dom';

const UserEdit = () => {
  const { params: { id: editEventId } } = useMatch('admin/events/:id/edit');
  const { page,
    totalPage,
    loading,
    resource,
    resources,
    setPage,
    resourcesUpdated,
    listResources,
    getResource,
    createResource,
    updateResource,
    deleteResource, } = useCURDStore({ resourcesName: 'events' });
  const { resources: badges, listResources: listBadges } = useCURDStore({ resourcesName: 'events/badgets' });
  const { resources: organizations, listResources: listOrganizations } = useCURDStore({ resourcesName: 'organizations?all=true&disabled=false' });
  const [editEventObject, setEditEventObject] = useState({})
  const [editEventDetail, setEditEventDetail] = useState('')
  const [editEventLocation, setEditEventLocation] = useState('')

  const handleEditSubmit = () => {
    updateResource(editEventId, {
      organization_id: editEventObject?.organization_id,
      name: editEventObject?.name,
      online: editEventObject?.online,
      from_date: editEventObject?.from_date,
      to_date: editEventObject?.to_date,
      description: editEventObject?.description,
      pic_url: editEventObject?.pic_url,
      cms_data: editEventDetail,
      location: editEventLocation,
      time: editEventObject?.time,
      Badges: editEventObject?.Badges,
    })
  }

  useEffect(() => {
    const fetchEditUser = async () => {
      await getResource(editEventId)
      await listBadges({})
      await listOrganizations({})
    }
    fetchEditUser()
  }, [editEventId])
  useEffect(() => {
    const payload = {
      ...resource,
      Badges: resource?.Badges?.map(badge => ({ id: badge.id })),
    }
    setEditEventObject(payload)
    setEditEventDetail(resource.cms_data)
    setEditEventLocation(resource.location)
  }, [resource])

  return <>{ (!editEventObject?.id && !!badges && !!organizations) && <div>Loading...</div> || 
    <EventEditView {...{
      badges,
      organizations,
      editEventObject,
      setEditEventObject,
      editEventDetail,
      setEditEventDetail,
      editEventLocation,
      setEditEventLocation,
      handleEditSubmit,
      resourcesUpdated,
    }} />
  }</>
}

export default UserEdit