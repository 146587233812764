import GoogleLogin from 'react-google-login';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { Button, Center, Container, Group, Title, Text } from '@mantine/core';
import { motion, useAnimation } from "framer-motion"
import { useEffect } from 'react';
import './login.css';
import { useNavigate } from 'react-router-dom';

const primaryButton = {
  variant: "gradient",
  gradient: { from: 'teal', to: 'lime', deg: 105 },
  radis: "xl"
}


const loginAnimationStates = {
  group: {
    init: {
      opacity: 0,
      scale: 0.8,
    },
    stay: {
      opacity: 1,
      scale: 1,
    },
    leave: {
      opacity: 0,
    }
  },
  // spotlight: {
  //   init: (i = 2.1) => ({
  //     opacity: 0,
  //     height: `calc((100% - 280px) * 1.5 * ${i})`,
  //     width: `calc(100% * 1.5 * ${i})`,
  //     left: `calc(-1 * 100% * 1.5 * ${i*i} * 0.18)`,
  //     top: `calc(-1 * (100% - 500px) * 1.5 * ${i*4} * 0.18)`,
  //   }),
  //   stay: (i = 1) => ({
  //     opacity: 1,
  //     height: `calc((100% - 280px) * 1.5 * ${i})`,
  //     width: `calc(100% * 1.5 * ${i})`,
  //     left: `calc(-1 * 100% * 1.5 * ${i} * 0.18)`,
  //     top: `calc(-1 * (100% - 500px) * 1.5 * ${i} * 0.18)`,
  //   }),
  //   leave: (i = 0.1) => ({
  //     opacity: 1,
  //     height: `calc((100% - 200px) * 1.5 * ${i})`,
  //     width: `calc(100% * 1.5 * ${i})`,
  //     left: `calc(-1 * 100% * 1.5 * ${i - 1} * 0.18)`,
  //     top: `calc(-1 * (100% - 200px) * 1.5 * ${i - 1} * 0.18)`,
  //   }),
  // }
}

function Login() {
  const controls = useAnimation()
  const navigate = useNavigate();

  const handleLogin = async googleData => {
    const res = await fetch(process.env.REACT_APP_API_URL+"/auth/users/google/redirect", {
      method: "POST",
      body: JSON.stringify({
        accessToken: googleData.tokenId
      }),
      headers: {
        "Content-Type": "application/json"
      }
    })
    const data = await res.json()
    localStorage.setItem('login', JSON.stringify(data));
    await controls.start('leave');
    navigate("/");
  }

  const handleError = err => {
    console.log(err)
  }

  const particlesInit = async (main) => {
    await loadFull(main);
  };

  useEffect(() => {
    controls.start("stay")
  }, [])
  return (
    <Container size="md" px="md">
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={{
          fullScreen: {
            enable: true
          },
          detectRetina: true,
          fpsLimit: 50,
          emitters: {
            direction: "top",
            life: {
              count: 0,
              duration: 0.1,
              delay: 0.1
            },
            rate: {
              delay: 0.2,
              quantity: 1
            },
            size: {
              width: 100,
              height: 0
            },
            position: {
              y: 100,
              x: 50
            }
          },
          particles: {
            number: {
              value: 0
            },
            destroy: {
              mode: "split",
              split: {
                count: 1,
                factor: { value: 1 / 3 },
                rate: {
                  value: 100
                },
                particles: {
                  color: {
                    value: ["#5bc0eb", "#fde74c", "#9bc53d", "#e55934", "#fa7921"]
                  },
                  stroke: {
                    width: 0
                  },
                  number: {
                    value: 0
                  },
                  collisions: {
                    enable: false
                  },
                  opacity: {
                    value: 1,
                    animation: {
                      enable: true,
                      speed: 0.3,
                      minimumValue: 0.1,
                      sync: false,
                      startValue: "max",
                      destroy: "min"
                    }
                  },
                  shape: {
                    type: "circle"
                  },
                  size: {
                    value: { min: 3, max: 5 },
                    animation: {
                      enable: false
                    }
                  },
                  life: {
                    count: 1,
                    duration: {
                      value: {
                        min: 2,
                        max: 4 
                      }
                    }
                  },
                  move: {
                    enable: true,
                    gravity: {
                      enable: false
                    },
                    speed: 1,
                    direction: "none",
                    random: true,
                    straight: false,
                    outMode: "destroy"
                  }
                }
              }
            },
            life: {
              count: 1
            },
            shape: {
              type: "line"
            },
            size: {
              value: { min: 1, max: 100 },
              animation: {
                enable: true,
                sync: true,
                speed: 150,
                startValue: "random",
                destroy: "min"
              }
            },
            stroke: {
              color: {
                value: "#0303030"
              },
              width: 2
            },
            rotate: {
              path: true
            },
            move: {
              enable: true,
              gravity: {
                acceleration: 15,
                enable: true,
                inverse: true,
                maxSpeed: 100
              },
              speed: { min: 10, max: 20 },
              outModes: {
                default: "destroy",
                top: "none"
              },
              trail: {
                fillColor: "#23485c",
                enable: true,
                length: 10
              }
            }
          }
      }}
    />
      <Center style={{ height: '100vh'}}>
        <motion.div 
          variants={loginAnimationStates.group}
          initial="init"
          animate={controls}>
            <Center style={{ width: '100%', marginTop: 30, marginBottom: 20, flexDirection: 'column' }}>
            <Text weight={700} sx={(theme) => ({
              zIndex: '100',
              textAlign: 'center',
              color: 'white',
              fontSize: '60px',
              letterSpacing: 2,
              bold: true,
              width: '450px',
              maxWidth: '100%',
              marginBottom: '20px',
              textShadow:'0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)'
             })}
            >
              FIREWORKS
            </Text>
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              onSuccess={handleLogin}
              onFailure={handleError}
              render={(renderProps) => <motion.div
                whileHover={{
                  scale: 1.1,
                  transition: { duration: 0.5 },
                }}
                whileTap={{ scale: 0.9 }}>
                <Button
                  size="md"
                  onClick={renderProps.onClick}
                  {...primaryButton}
                >Login with Google</Button>
              </motion.div>}
              cookiePolicy={'single_host_origin'}
            ></GoogleLogin>
          </Center>
        </motion.div>
      </Center>
    </Container>
  );
}

export default Login;
