import { AspectRatio, Image, Box, Checkbox, Space, Title, InputWrapper, Input, MediaQuery, MultiSelect, Button, Textarea, Select, Container } from "@mantine/core";
import { Photo } from 'tabler-icons-react';
import { DatePicker } from '@mantine/dates';
import { Dropzone } from "@mantine/dropzone";
import dropzoneChildren, {onUpload} from './dropzoneChildren';
import RichTextEditor from "@mantine/rte";

const ServiceTags = [
  'Education',
  'Community',
  'Social',
  'Medical & Integrated Health',
  'Rehabilitation',
  'Pre-school',
];

const EventsEditView = ({
  badges,
  organizations,
  editEventObject,
  setEditEventObject,
  editEventDetail,
  setEditEventDetail,
  editEventLocation,
  setEditEventLocation,
  handleEditSubmit,
  resourcesUpdated,
  orgLock,
}) => {
  return (
    <Container sx={(theme) => ({
      marginTop: theme.spacing.md,
    })}>
    <div style={{ borderRadius: 3, backgroundColor: '#f6f6f6', padding: 10 }}>

      <Box>

          {!orgLock &&
            <InputWrapper
              id="input-demo"
              required
              label="Organization"
            >
              <Select
                placeholder="Event's organization"
                nothingFound="No options"
                maxDropdownHeight={280}
                value={editEventObject?.organization_id}
                onChange={(e) => setEditEventObject({ ...editEventObject, organization_id: e })}
                data={organizations?.map(s => ({ label: s.name, value: s.id }))}
              />
            </InputWrapper>
          }

      <Space h="md" />
        <Title order={1} sx={(theme) => ({
          color: theme.colors.green[5],
        })}>{!editEventObject?.id ? 'Create' : 'Edit'} Events</Title>

        <Space h="xl" />

        <InputWrapper
          id="input-demo"
          label="Event's name"
          required
        >
          <Input
            placeholder="Event's name"
            rightSectionWidth={70}
            defaultValue={editEventObject?.name}
            onChange={(e) => setEditEventObject({ ...editEventObject, name: e.target.value })}
            styles={{ rightSection: { pointerEvents: 'none' } }}
          />
        </InputWrapper>

        <Space h="xl" />

        <InputWrapper
          id="input-demo"
          label="Please upload a photo of the event"
          required
        >
          <Dropzone
            multiple={false}
            onDrop={(files) => onUpload(files).then(url => setEditEventObject({ ...editEventObject, pic_url: url }))}
            onReject={(files) => console.log('rejected files', files)}
            maxSize={3 * 1024 ** 2}
            accept={['image/png', 'image/jpeg', 'image/gif', 'image/webp']}>
            {(status) => dropzoneChildren(<div style={{ width: 200, marginLeft: 'auto', marginRight: 'auto' }}>
            <Image
              src={editEventObject?.pic_url}
              imageProps={{ referrerpolicy: "no-referrer" }}
            />
          </div>, status)}
          </Dropzone>
        </InputWrapper>

        <Space h="xl" />

        <Checkbox
          label="It is an online event."
          color="teal"
          defaultChecked={editEventObject?.online}
          onChange={(e) => { setEditEventObject({ ...editEventObject, online: e.target.checked }) }}
        />

        <Space h="xl" />

        <DatePicker
          placeholder="Pick date"
          label="Event start date"
          required
          defaultValue={new Date(Date.parse(editEventObject.from_date))}
          onChange={(date) => setEditEventObject({ ...editEventObject, from_date: date.toISOString() })}
        />

        <Space h="sm" />

        <DatePicker
          placeholder="Pick date"
          label="Event end date"
          required
          defaultValue={new Date(Date.parse(editEventObject.to_date))}
          onChange={(date) => setEditEventObject({ ...editEventObject, to_date: date.toISOString() })}
        />

        <Space h="xl" />

        <InputWrapper
          id="input-demo"
          label="Event's time"
        >
          <Input
            placeholder="Event's time"
            rightSectionWidth={70}
            styles={{ rightSection: { pointerEvents: 'none' } }}
            defaultValue={editEventObject.time}
            onChange={(e) => setEditEventObject({ ...editEventObject, time: e.target.value })}
          />
        </InputWrapper>

        <Space h="xl" />

        <InputWrapper
          id="input-demo"
          label="Location or Meeting Link"
        >
          <RichTextEditor value={editEventLocation} onImageUpload={(f) => onUpload([f])} onChange={setEditEventLocation} />
        </InputWrapper>

        <Space h="xl" />

        <InputWrapper
          id="input-demo"
          label="Please select type of services for the organization"
        >
          <MultiSelect
            data={badges?.map(b => ({label: b.name, value: b.id}))}
            limit={30}
            searchable
            nothingFound="Nothing found"
            style={{ flex: 1 }}
            sx={(theme) => ({
              backgroundColor: theme.colors.navy[2],
            })}
            defaultValue={editEventObject?.Badges?.map(b => b.id)}
            onChange={(e) => setEditEventObject({ ...editEventObject, Badges: e })}
          />
        </InputWrapper>

        <Space h="xl" />

        <Textarea
          placeholder="Under 200 words"
          label="Short description of the event, this part is shown on the event list page"
          defaultValue={editEventObject?.description}
          autosize
          onChange={(e) => setEditEventObject({ ...editEventObject, description: e.target.value })}
        />

        <Space h="xl" />

        <InputWrapper
          id="input-demo"
          label="Major Event detail section, this part is shown on the event detail page"
        >
        <RichTextEditor value={editEventDetail} onImageUpload={(f) => onUpload([f])} onChange={setEditEventDetail} />
        </InputWrapper>
        <Space h="xl" />

        <Button onClick={handleEditSubmit} variant="gradient" gradient={{ from: 'teal', to: 'lime', deg: 105 }}>{resourcesUpdated && 'Saved' || 'Save'}</Button>
    </Box >
    </div ></Container>);
}
export default EventsEditView; 