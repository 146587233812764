import UserEditView from '../components/userEditView';
import useCURDStore from '../hooks/useCURDStore';
import { useState, useEffect } from 'react';

const UserEdit = () => {
  const { page,
    totalPage,
    loading,
    resource,
    resources,
    setPage,
    resourcesUpdated,
    listResources,
    getResource,
    createResource,
    updateResource,
    deleteResource, } = useCURDStore({ resourcesName: 'users' });
  const { resources: schools, listResources: getSchools } = useCURDStore({ resourcesName: 'schools?all=true&disabled=false' });
  const [editUserObject, setEditUserObject] = useState({})

  useEffect(() => {
    const fetchEditUser = async () => {
      await getSchools({})
    }
    fetchEditUser()
  }, [])

  const handleEditSubmit = () => {
    createResource({
      school_id: editUserObject?.school_id,
      first_name: editUserObject?.first_name,
      last_name: editUserObject?.last_name,
      pronouns: editUserObject?.pronouns,
      picture: editUserObject?.picture,
      role: editUserObject?.role,
      about: editUserObject?.about,
      twitter: editUserObject?.twitter,
      instagram: editUserObject?.instagram,
      facebook: editUserObject?.facebook,
      email: editUserObject?.email,
    })
  }

  return <>{schools && <UserEditView {...{
    schools,
    editUserObject,
    setEditUserObject,
    handleEditSubmit,
    resourcesUpdated,
  }}/> || <div>loading...</div>}</>
}

export default UserEdit