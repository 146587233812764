import React from 'react';
import { ChevronRight } from 'tabler-icons-react';
import { UnstyledButton, Group, Avatar, Text, Box, useMantineTheme } from '@mantine/core';

export function NavUser({
  firstName, lastName, picture, handleOnClick, hours
}) {
  const theme = useMantineTheme();

  return (
    <Box
      sx={{
        paddingTop: theme.spacing.sm,
        borderTop: `1px solid ${
          theme.colors.navy[7]
        }`,
      }}
    >
      <UnstyledButton
        onClick={handleOnClick}
        sx={{
          display: 'block',
          width: '100%',
          padding: theme.spacing.xs,
          borderRadius: theme.radius.sm,
          color: theme.colors.blue[0],

          '&:hover': {
            backgroundColor:
              theme.colors.blue[9],
          },
        }}
      >
        <Group>
          <Avatar
            src={picture}
            radius="xl"
          />
          <Box sx={{ flex: 1 }}>
            <Text size="sm" weight={500}>
              {firstName} {lastName}
            </Text>
            <Text size="sm" weight={500}>
              {Math.ceil(hours / 60) || 0} hour(s)
            </Text>
          </Box>

          <ChevronRight size={18} />
        </Group>
      </UnstyledButton>
    </Box>
  );
}