import {
  Button,
  Divider,
  Container,
  Card,
  Image,
  Title,
  Group,
  Text,
  Grid,
  Space,
  Badge,
  TypographyStylesProvider,
  Paper,
} from '@mantine/core';
import { CalendarTime, Confetti } from 'tabler-icons-react';
import { Link, useMatch } from 'react-router-dom';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import api from '../api';
import useUserStore from '../hooks/useUserStore';
import Organization from '../components/organization';

const Event = () => {
  const match = useMatch('/events/:id');
  const [user, fetchUser] = useUserStore();
  const [event, setEvent] = useState({})
  const [joined, setJoined] = useState(false)
  useEffect(() => {
    const fetchEvent = async () => {
      const res = (await api().get('events/' + match.params.id)).data;
      return res
    };
    fetchEvent().then(e => {
      setEvent(e)
      setJoined(e.users.map(u => u.users.id).includes(user.id))
    })
  }, [user])

  const toggleJoin = async () => {
    if (joined) {
      const res = await api().post('events/' + match.params.id + '/unjoin');
      setJoined(false)
    } else {
      const res = await api().post('events/' + match.params.id + '/join');
      setJoined(true)
    }
  }
  return <Container sx={(theme) => ({ paddingTop: theme.spacing.md })}>
    <Paper shadow="sm" radius="md" p="xl" sx={(theme) => ({
      backgroundColor: theme.colors.navy[2],
    })}>
      <Text
        component={Link}
        to={'/events'}
        variant="gradient"
        gradient={{ from: 'lime', to: 'yellow', deg: 45 }}
        weight={700}
      >Event</Text> <Text component={'span'}> > {event.name}</Text>
    </Paper>
    <Space h='md' />
    <Card shadow="sm" p="lg" sx={(theme) => ({
      background: theme.colors.white[0],
    })}>
        <Card.Section>
          <Image src={event?.pic_url} height={300} />
        </Card.Section>
        <Title order={1} sx={(theme) => ({
          margin: `${theme.spacing.lg}px 0`,
        })}>{event?.name}</Title>
        <Group spacing="xs">
          <CalendarTime size={30} />
          <Text weight={500} size="md">
          {dayjs(event?.from_date).format('DD MMMM YYYY')} - {dayjs(event?.to_date).format('DD MMMM YYYY')}
          </Text>
      </Group>
      <Divider my="sm" />
      {
        joined &&
        <Button leftIcon={<Confetti />} onClick={toggleJoin} variant="outline" size="md">Joined</Button> ||
        <Button leftIcon={<Confetti />} onClick={toggleJoin} variant="gradient" gradient={{ from: 'teal', to: 'lime', deg: 105 }} size="md">Join</Button>
      }
    </Card>
    <Space h="md" />
    <Grid columns={12}>
      <Grid.Col md={8} sm={12}>
        <Card shadow="sm" p="lg" sx={(theme) => ({
          background: theme.colors.white[0],
        })}>
          <Title order={2} sx={(theme) => ({
            margin: `${theme.spacing.md}px 0`,
          })}>About this event</Title>
          <TypographyStylesProvider>
            <div dangerouslySetInnerHTML={{ __html: event?.cms_data}} />
          </TypographyStylesProvider>
        </Card>
        <Space h="md" />
        <Text
          component="span"
          variant="gradient"
          gradient={{ from: 'red', to: 'yellow', deg: 45 }}
          weight={700}
          sx={(theme) => ({
            fontSize: '150%',
            display: 'inline-block',
            marginBottom: theme.spacing.sm
          })}
        >
          About the organization
        </Text>
        <Organization {... event?.organization} />
      </Grid.Col>
      <Grid.Col md={4} sm={12}>
        <Card shadow="sm" p="lg" sx={(theme) => ({
          background: theme.colors.white[0],
        })}>
          <Title order={2} sx={(theme) => ({
            margin: `${theme.spacing.sm}px 0`,
          })}>Location</Title>
          <TypographyStylesProvider>
            <div dangerouslySetInnerHTML={{ __html: event?.location }} />
          </TypographyStylesProvider>
          <Title order={2} sx={(theme) => ({
            margin: `${theme.spacing.sm}px 0`,
          })}>Time</Title>
          <TypographyStylesProvider>
            <div dangerouslySetInnerHTML={{ __html: event?.time}} />
          </TypographyStylesProvider>
        </Card>
      </Grid.Col>
    </Grid>
  </Container>
}
export default Event;