import UserEditView from '../components/userEditView';
import useCURDStore from '../hooks/useCURDStore';
import { useLocation, useNavigate, useMatch } from 'react-router-dom';
import { useState, useEffect } from 'react';

const UserEdit = () => {
  const { params: { id: editUserId } } = useMatch('admin/users/:id/edit');
  const { page,
    totalPage,
    loading,
    resource,
    resources,
    setPage,
    resourcesUpdated,
    listResources,
    getResource,
    createResource,
    updateResource,
    deleteResource, } = useCURDStore({ resourcesName: 'users' });
  const { resources: organizations, listResources: getOrganizations } = useCURDStore({ resourcesName: 'organizations?all=true&disabled=false' });
  const { resources: schools, listResources: getSchools } = useCURDStore({ resourcesName: 'schools?all=true&disabled=false' });
  const [editUserObject, setEditUserObject] = useState({})

  useEffect(() => {
    const fetchEditUser = async () => {
      await getResource(editUserId)
      await getSchools({})
      await getOrganizations({})
    }
    fetchEditUser()
  }, [editUserId])
  useEffect(() => {
    setEditUserObject(resource)
  }, [resource])
  useEffect(() => {
  })

  const handleEditSubmit = () => {
    updateResource(editUserId, {
      student_graduation_year: editUserObject?.student_graduation_year,
      school_id: editUserObject?.school_id,
      organization_id: editUserObject?.organization_id,
      first_name: editUserObject?.first_name,
      last_name: editUserObject?.last_name,
      pronouns: editUserObject?.pronouns,
      picture: editUserObject?.picture,
      role: editUserObject?.role,
      about: editUserObject?.about,
      twitter: editUserObject?.twitter,
      instagram: editUserObject?.instagram,
      facebook: editUserObject?.facebook,
      email: editUserObject?.email,
      role_confirmed: editUserObject?.role_confirmed,
    })
  }

  return <>{!!schools && !!organizations && <UserEditView {...{
    schools,
    organizations,
    editUserObject,
    setEditUserObject,
    handleEditSubmit,
    resourcesUpdated,
  }}/> || <div>loading...</div>}</>
}

export default UserEdit