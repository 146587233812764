import { Photo, Upload } from 'tabler-icons-react';
import { Text } from "@mantine/core";
import api from '../api';

export const onUpload = async (file) => {
  console.log('file', file);
  const formData = new FormData();
  formData.append("upload", ...file);
  const respond = await api(true).post('/upload', formData);

  return respond.data.data;
}

const dropzoneChildren = (previewNode, status, theme) => {
  console.log(status)
  return <>{previewNode || 0}
  <div>
    <Text size="xl" inline>
      Drag images here or click to select files
    </Text>
  </div></>
}

export default dropzoneChildren;