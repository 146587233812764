import { Badge, Button, Card, Group, Title, Image, Text } from "@mantine/core";
import { CircleCheck, CirclePlus } from "tabler-icons-react";

const Organization = ({ id, name, img_src, badges, description, followed, handleFollow }) => {
  return <Card shadow="sm" p="lg" style={{ marginBottom: 40 }} sx={(theme) => ({
    backgroundColor: theme.colors.white[0],
  })}>
    <Card.Section component="div">
      <Image
        src={img_src}
        height={360}
      />
    </Card.Section>

    <Group position="apart" sx={(theme) => ({ marginBottom: 5, marginTop: theme.spacing.sm })}>
      <Title order={2}>{name}</Title>

      <div>
        {badges?.map((badge, index) => (
          <Badge color="pink" variant="light" style={{ marginLeft: 5 }}>{badge.name}</Badge>
        ))}
      </div>
    </Group>

    <Text size="sm" sx={(theme) => ({ color: theme.colors.navy[3], lineHeight: 1.5 })}>{description}</Text>

    {handleFollow &&
    <Button
      variant={followed ? "outline" : "light"}
      color="blue"
      fullWidth
      style={{ marginTop: 14 }}
      leftIcon={followed && <CircleCheck size={25} /> || <CirclePlus size={25} />}
      onClick={() => handleFollow(id)}
    >
      {followed ? "Followed" : "Follow"}
    </Button>}
  </Card>;
}

export default Organization;