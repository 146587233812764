import React from 'react';
import ReactDOM from 'react-dom';
import dayjs from 'dayjs';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { MantineProvider, Text } from '@mantine/core';
import { useLocation, BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './route/Login';
import Home from './components/home';
import Index from './route/index';
import Onboarding from './route/onboarding';
import Organizations from './route/organizations';
import Profile from './route/profile';
import Events from './route/events';
import Event from './route/event';
import MyEvents from './route/myevents';
import AdminListView from './route/adminListView';
import UserEdit from './route/userEdit';
import UserCreate from './route/userCreate';
import EventEdit from './route/eventEdit';
import EventCreate from './route/eventCreate';
import SchoolEdit from './route/schoolEdit';
import SchoolCreate from './route/schoolCreate';
import StudentsList from './route/studentsList';
import EventAttendances from './route/eventAttendances';
import OrganizationCreate from './route/organizationCreate';
import OrganizationEdit from './route/organizationEdit';
import PopoverButton from './components/popoverButton';
import School from './route/school';
import Organization from './route/organization';
import EventCreateForOrganization from './route/eventCreateForOrganization';
import {Button} from '@mantine/core';
import api from './api';

const theme = {
  fontFamil1y: 'barlow, sans-serif',
  colorScheme: 'light',
  colors: {
    white: ['#edeef2'],
    brand: ['#AFDDB8', '#AFDDB8'],
    navy: ['#54b1e3', '#4ea5d3', '#4898c3', '#438cb4', '#3d80a4', '#387595', '#326986', '#2d5e78', '#28536a', '#23485c'],
    purple: ['#bcbeed', '#b0b2df', '#a4a5d0', '#9899c2', '#8c8db4', '#8082a7', '#757699', '#6a6b8c', '#5f607f', '#545572'],
    blue: ['#c9e7ee', '#b7d7df', '#a6c7cf', '#95b8c0', '#84a9b1', '#7399a3', '#638b94', '#527c86', '#426e79', '#31606b'],
    green: ['#afddb8', '#a0d1a9', '#91c69a', '#82ba8b', '#73af7c', '#64a46e', '#55985f', '#468d51', '#368243', '#257735'],
    pink: ['#edc3e1', '#e1b2d3', '#d5a1c6', '#ca90b9', '#be80ab', '#b3709e', '#a75f91', '#9b4f84', '#903e78', '#842c6b'],
  },
  primaryColor: 'teal',
}

export const DisableOrEnableButton = ({ resourceName, o, reload }) => {
  const { disabled } = o;
  return <PopoverButton buttonName={disabled ? 'Enable' : 'Disable'} props={{ position: "left" }} buttonProps={{
    variant: "gradient", gradient: !!disabled ? { from: 'green', to: 'lime', deg: 60 } : { from: 'purple', to: 'red', deg: 60 },
  }}><Text>Are you sure?</Text><br /><Button onClick={() => disableOrEnable(disabled, o.id, resourceName, reload)}>Confirm</Button></PopoverButton>
}

export const disableOrEnable = (disabled, id, resourceName, reload) => {
  console.log(resourceName)
  if (disabled) {
    return api().post(`/${resourceName}/${id}/enable`).then((o) => {
      reload()
    })
  }
  return api().post(`/${resourceName}/${id}/disable`).then((o) => {
    reload()
  })
}

const adminUserSetting = {
  resources: 'users',
  schema: [
    {name: 'id', label: 'ID'},
    {name: 'first_name', label: 'First Name'},
    {name: 'last_name', label: 'Last Name'},
    {name: 'email', label: 'Email'},
    {name: 'createdAt', label: 'Member Since'},
  ],
  action: [
    {action: 'edit', label: 'Edit', href: (o) => `${o.id}/edit`},
    { component: (o, reload) => <DisableOrEnableButton resourceName='users'
     o={o} reload={reload} /> }
  ]
}

const adminOrganizationSetting = {
  createAllowed: true,
  resources: 'organizations',
  schema: [
    {name: 'id', label: 'ID'},
    {name: 'name', label: 'Name'},
    {name: 'following', label: 'Followers'},
  ],
  action: [
    {action: 'edit', label: 'Edit', href: (o) => `${o.id}/edit`},
    { component: (o, reload) => <DisableOrEnableButton resourceName='organizations' o={o} reload={reload} /> }
  ]
}

const adminEventSetting = {
  createAllowed: true,
  resources: 'events?all=true&disabled=false',
  schema: [
    {name: 'id', label: 'ID'},
    {name: 'name', label: 'Name'},
    {name: 'from_date', label: 'Start date', display: (o) => <>{dayjs(o.from_date).format('DD MMM YYYY')}</>},
    {name: 'to_date', label: 'End date', display: (o) => <>{dayjs(o.to_date).format('DD MMM YYYY')}</>},
  ],
  action: [
    {action: 'edit', label: 'Edit', href: (o) => `${o.id}/edit`},
    {action: 'Attendees', label: 'Attendees', href: (o) => `${o.id}/attendees`},
    { component: (o, reload) => <DisableOrEnableButton resourceName='events' o={o} reload={reload} /> }
  ]
}

const adminSchoolSetting = {
  createAllowed: true,
  resources: 'schools',
  schema: [
    {name: 'id', label: 'ID'},
    {name: 'name', label: 'Name'},
    {name: 'total_hours', label: 'Total hours', display: (o) => <>{Math.ceil(o.total_hours / 60)}</>},
  ],
  action: [
    {action: 'edit', label: 'Edit', href: (o) => `${o.id}/edit`},
    {action: 'student', label: 'Students', href: (o) => `${o.id}/students`},
    { component: (o, reload) => <DisableOrEnableButton resourceName='schools' o={o} reload={reload} /> }
  ]
}

ReactDOM.render(
  <MantineProvider theme={theme}>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />}>
            <Route path="/" element={<Index />} />
            <Route path="my_events" element={<MyEvents />} />
            <Route path="events" element={<Events />} />
            <Route path="events/:id" element={<Event />} />
            <Route path="organizations" element={<Organizations />} />
            <Route path="profile" element={<Profile />} />
            <Route path="teacher" element={<School />} />
            <Route path="organization" element={<Organization />} />
            <Route path="organization/create" element={<EventCreateForOrganization />} />
            <Route path="admin/users" element={<AdminListView {...adminUserSetting} />} />
            <Route path="admin/organizations" element={<AdminListView {...adminOrganizationSetting} />} />
            <Route path="admin/events" element={<AdminListView {...adminEventSetting} />} />
            <Route path="admin/schools" element={<AdminListView {...adminSchoolSetting} />} />
            <Route path="admin/schools/create" element={<SchoolCreate />} />
            <Route path="admin/schools/:id/edit" element={<SchoolEdit />} />
            <Route path="admin/schools/:id/students" element={<StudentsList />} />
            <Route path="admin/organizations/create" element={<OrganizationCreate />} />
            <Route path="admin/organizations/:id/edit" element={<OrganizationEdit />} />
            <Route path="admin/events/create" element={<EventCreate />} />
            <Route path="admin/events/:id/attendees" element={<EventAttendances />} />
            <Route path="admin/events/:id/edit" element={<EventEdit />} />
            <Route path="admin/users/create" element={<UserCreate />} />
            <Route path="admin/users/:id/edit" element={<UserEdit />} />
          </Route>
          <Route path="/onboarding" element={<Onboarding />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </MantineProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
