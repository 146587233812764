import useCURDStore from '../hooks/useCURDStore';
import { useState, useEffect } from 'react';
import SchoolEditView from '../components/schoolEditView';
import { useNavigate } from "react-router-dom";

const SchoolCreate = () => {
  const navigate = useNavigate();
  const { page,
    totalPage,
    loading,
    resource,
    resources,
    setPage,
    resourcesUpdated,
    listResources,
    getResource,
    createResource,
    updateResource,
    deleteResource, } = useCURDStore({ resourcesName: 'schools' });
  const [editSchoolObject, setEditSchoolObject] = useState({})

  useEffect(() => {
    setEditSchoolObject(resource)
  }, [resource])

  const handleSubmit = async () => {
    const id = await createResource({
      name: editSchoolObject?.name,
      img_src: editSchoolObject?.img_src,
      description: editSchoolObject?.description,
    })
    navigate(`/admin/schools`);
  }

  return <>{
    <SchoolEditView {...{
      resource,
      resourcesUpdated,
      getResource,
      editSchoolObject,
      setEditSchoolObject,
      handleSubmit,
  }} />}</>
}

export default SchoolCreate