import {
  Container,
  Card,
  Image,
  Text,
  Avatar,
  Space,
  Group,
  Title,
  Button,
  InputWrapper,
  Input,
  Textarea,
  Badge,
} from '@mantine/core';
import { useState } from 'react';
import { BrandFacebook, BrandInstagram, BrandTwitter, At } from 'tabler-icons-react';
import api from '../api'
import useUserStore from '../hooks/useUserStore';

const Profile = () => {
  const [user, fetchUser] = useUserStore();
  const [edit, setEdit] = useState(false);
  const [userEdited, setUserEdited] = useState(user);
  const handleEdit = () => {
    setEdit(!edit)
  }
  const handleUserEdited = (input, label) => {
    setUserEdited({
      ...userEdited,
      [label]: input,
    })
  }
  const handleSubmit = async () => {
    const userData = { ...user, ...userEdited }
    await api().post('users/me', userData).then(() => {
      setEdit(false)
      fetchUser();
    })
  }

  return <Container sx={(theme) => ({
    marginTop: theme.spacing.md,
  })}>
    <Card
      shadow="sm"
      p="xl"
    >
      <Card.Section>
        <Image src="https://muffinstoeat.files.wordpress.com/2011/07/smile-colour1.png" height={300} alt="Smile" />
        <Button onClick={handleEdit} variant={edit ? "default": "gradient"} gradient={{ from: 'teal', to: 'lime', deg: 105 }} sx={(theme) => ({
          position: 'absolute',
          right: 30,
          marginTop: -60
        })}
        >{edit ? "Cancel" :"Edit" } </Button>
        <Avatar color="blue" size="xl" src={user.picture} sx={(theme) => ({
          height: 120,
          width: 120,
          borderRadius: '50%',
          marginTop: -60, marginLeft: 20,
        })} />

      </Card.Section>

      <Space h="xl" />

      <Text weight={500} size="lg">
        {edit &&
          <>
            <InputWrapper
              required
              label="First name"
            >
              <Input defaultValue={user.first_name} onChange={(v) => handleUserEdited(v.target.value, 'first_name')} />
            </InputWrapper>
            <InputWrapper
              required
              label="Last name"
            >
              <Input defaultValue={user.last_name} onChange={(v) => handleUserEdited(v.target.value, 'last_name')} />
            </InputWrapper>
          </>
          || <>{user.first_name} {user.last_name}</>
        }
      </Text>

      <Text weight={500} size="sm">
        {user?.school?.name || user?.organization?.name}
        {
          user?.role?.includes('STUDENT') && <>
            <Text weight={400} size="sm" color="grey">
              Class of {user?.student_graduation_year}
            </Text>
          </>
        }
      </Text>

      <Text weight={500} size="sm">
        {user?.role?.includes('ADMIN') && <Badge variant="filled" color="red" size="md" radius="sm">Fireworks admin{user?.role_confirmed &&' ✅ Verified' || ' 🤔 Role Pending Verification'}</Badge>}
        {user?.role?.includes('ORGANIZATION') && <Badge variant="filled" color="pink" size="md" radius="sm">Organization Admin{user?.role_confirmed &&' ✅ Verified' || ' 🤔 Role Pending Verification'}</Badge>}
        {user?.role?.includes('TEACHER') && <Badge variant="filled" color="green" size="md" radius="sm">Teacher{user?.role_confirmed &&' ✅ Verified' || ' 🤔 Role Pending Verification'}</Badge>}
        {user?.role?.includes('STUDENT') && <Badge variant="filled" color="lime" size="md" radius="sm">Student {user?.student_school_approved && ' ✅ Verified' || ' 🤔 Student identification Pending'}</Badge>}
      </Text>

      <Text weight={400} size="sm" color="grey">
        Hong Kong
      </Text>

      <Text weight={400} size="sm">
        Hour(s) of service: {user?.hours}
      </Text>


      <Space h="lg" />


      {edit &&
        <>
          <InputWrapper
            required
          >
            <Input
              icon={<BrandTwitter size={16} />}
              placeholder="Your twitter"
              rightSectionWidth={70}
              styles={{ rightSection: { pointerEvents: 'none' } }}
              defaultValue={user.twitter} onChange={(v) => handleUserEdited(v.target.value, 'twitter')}
            />
          </InputWrapper>
        </>
        ||
        <>  <Group spacing="xs">
          <BrandTwitter size={16} />
        <Text weight={500} size="sm">{user.twitter && <a href={`https://twitter.com/${user.twitter}`} target="_blank">{user.twitter}</a> || "N/A"}</Text>
        </Group></>
      }


      <Space h="lg" />


      {edit &&
        <>
          <InputWrapper
            required
          >
            <Input
              icon={<BrandFacebook size={16} />}
              placeholder="Your facebook"
              rightSectionWidth={70}
              styles={{ rightSection: { pointerEvents: 'none' } }}
              defaultValue={user.facebook} onChange={(v) => handleUserEdited(v.target.value, 'facebook')}
            />
          </InputWrapper>
        </>
        ||
        <><Group spacing="xs">
          <BrandFacebook size={16} />
          <Text weight={500} size="sm">{user.facebook && <a href={`https://facebook.com/${user.facebook}`} target="_blank">{user.facebook}</a> || "N/A"}</Text>
        </Group></>
      }


      <Space h="lg" />

      {edit &&
        <>
          <InputWrapper
            required
          >
            <Input
              icon={<BrandInstagram size={16} />}
              placeholder="Your instagram"
              styles={{ rightSection: { pointerEvents: 'none' } }}
              defaultValue={user.instagram} onChange={(v) => handleUserEdited(v.target.value, 'instagram')}
            />
          </InputWrapper>
        </>
        ||
        <><Group spacing="xs">
          <BrandInstagram size={16} />
          <Text weight={500} size="sm">{user.instagram && <a href={`https://instagram.com/${user.instagram}`} target="_blank">{user.instagram}</a> || "N/A"}</Text>
        </Group></>
      }

      <Space h="lg" />

      {edit &&
        <>
          <InputWrapper
            required
          >
            <Input
              icon={<At size={16} />}
              placeholder="Your email"
              styles={{ rightSection: { pointerEvents: 'none' } }}
              defaultValue={user.email} onChange={(v) => handleUserEdited(v.target.value, 'email')}
            />
          </InputWrapper>
        </>
        ||
        <><Group spacing="xs">
          <At size={16} />
          <Text weight={500} size="sm">{user.email || "N/A"}</Text>
        </Group></>
      }

      <Space h="lg" />

      <Text weight={500} size="md">About me</Text>
      {edit &&
        <>
          <Textarea
            placeholder="Under 100 words"
            label="Please describe yourself"
            defaultValue={user.about} onChange={(v) => handleUserEdited(v.target.value, 'about')}
          />
        </>
        ||
        <>
          <Text weight={500} size="sm">
            {user.about || "N/A 💩"}
          </Text></>
      }


      <Space h='lg' />
      {edit &&
        <Button onClick={handleSubmit} variant="gradient" gradient={{ from: 'teal', to: 'lime', deg: 105 }}>Submit</Button>
      }
    </Card>


  </Container>
}



export default Profile