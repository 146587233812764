import useCURDStore from '../hooks/useCURDStore';
import { useState, useEffect } from 'react';
import SchoolEditView from '../components/schoolEditView';
import { useMatch } from 'react-router-dom';

const SchoolEdit = () => {
  const { params: { id: editSchoolId } } = useMatch('admin/schools/:id/edit');
  const { page,
    totalPage,
    loading,
    resource,
    resources,
    setPage,
    resourcesUpdated,
    listResources,
    getResource,
    createResource,
    updateResource,
    deleteResource, } = useCURDStore({ resourcesName: 'schools' });
  const [editSchoolObject, setEditSchoolObject] = useState({})

  useEffect(() => {
    const fetchEditUser = async () => {
      await getResource(editSchoolId)
    }
    fetchEditUser()
  }, [editSchoolId])
  useEffect(() => {
    setEditSchoolObject(resource)
  }, [resource])

  const handleSubmit = () => {
    updateResource(editSchoolId, {
      name: editSchoolObject?.name,
      img_src: editSchoolObject?.img_src,
      description: editSchoolObject?.description,
    })
  }

  return <>{
    !!editSchoolObject.id && <SchoolEditView {...{
      resource,
      resourcesUpdated,
      getResource,
      editSchoolObject,
      setEditSchoolObject,
      handleSubmit,
  }} /> || <div>loading ...</div>}</>
}

export default SchoolEdit