import useUserStore from "../hooks/useUserStore";
import AdminListView from './adminListView';
import dayjs from 'dayjs';
import {
  Title,
  Paper,
  Text,
  Container,
  Group,
  Box,
  Card,
  Center,
  Space
} from '@mantine/core';
import Header from "../components/header";

const OrganizationInfo = ({organizationName}) => <Paper shadow="sm" radius="md" p="xl" sx={(theme) => ({
      backgroundColor: theme.colors.white,
    })}><Title>{organizationName}</Title>
</Paper>

const Organization = ({}) => {
  const [user, fetchUser] = useUserStore();
  if (user.organization && user.role_confirmed) {
    return <div>
      <OrganizationInfo
        organizationName={user.organization.name}
      />
      <Space  h='md' />
      <Header>
        Upcoming Events
      </Header>
      <AdminListView {...{
        createAllowed: true,
        resources: `events?organization_id=${user.organization.id}`,
        schema: [
          {name: 'id', label: 'ID'},
          {name: 'name', label: 'Name'},
          {name: 'from_date', label: 'Start date', display: (o) => <>{dayjs(o.from_date).format('DD MMM YYYY')}</>},
          {name: 'to_date', label: 'End date', display: (o) => <>{dayjs(o.to_date).format('DD MMM YYYY')}</>},
        ],
        action: [
          {action: 'edit', label: 'Edit', href: (o) => `/admin/events/${o.id}/edit`},
          {action: 'Attendees', label: 'Attendees', href: (o) => `/admin/events/${o.id}/attendees`},
        ]
      }} />
      <Space  h='md' />
      <Header>
        Past Events
      </Header>
      <AdminListView {...{
        resources: `events?organization_id=${user.organization.id}&past_event=true`,
        schema: [
          {name: 'id', label: 'ID'},
          {name: 'name', label: 'Name'},
          {name: 'from_date', label: 'Start date', display: (o) => <>{dayjs(o.from_date).format('DD MMM YYYY')}</>},
          {name: 'to_date', label: 'End date', display: (o) => <>{dayjs(o.to_date).format('DD MMM YYYY')}</>},
        ],
        action: [
          {action: 'edit', label: 'Edit', href: (o) => `/admin/events/${o.id}/edit`},
          {action: 'Attendees', label: 'Attendees', href: (o) => `/admin/events/${o.id}/attendees`},
        ]
      }} />
    </div>;
  } else {
    return <Paper shadow="sm" radius="md" p="xl" sx={(theme) => ({
      backgroundColor: theme.colors.navy[2],
    })}>
      <Text>Sorry, your role is not confirmed yet, please contact admin.</Text>
    </Paper>
  }
}

export default (props) => <Container sx={(theme) => ({
  marginTop: theme.spacing.md,
})}><Organization {...props} /></Container>