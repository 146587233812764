import { AspectRatio, Image, Box, Space, Title, InputWrapper, Input, Container, MultiSelect, Button, Textarea } from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import { Photo } from 'tabler-icons-react';
import dropzoneChildren, {onUpload} from './dropzoneChildren';

const OrganizationEditView = ({
  editOrganizationObject,
  setEditOrganizationObject,
  resourcesUpdated,
  handleSubmit,
  badges,
}) => {

  return <Container sx={(theme) => ({
    marginTop: theme.spacing.md,
  })}><div style={{ borderRadius: 3, backgroundColor: '#f6f6f6', padding: 10 }}>
    <Box>
      <Title order={1} sx={(theme) => ({
        color: theme.colors.green[5],
      })}>{editOrganizationObject.id ? 'Edit' : 'Create'} Organization</Title>

      <Space h="xl" />

      <InputWrapper
        id="input-demo"
        label="Title"
      >
        <Input
          placeholder="Organization's Name"
          rightSectionWidth={70}
          defaultValue={editOrganizationObject?.name}
          onChange={(e) => setEditOrganizationObject({ ...editOrganizationObject, name: e.target.value })}
          styles={{ rightSection: { pointerEvents: 'none' } }}
        />
      </InputWrapper>

      <Space h="md" />
      <InputWrapper
        id="input-demo"
        label="Please upload a photo of the organization"
      >
        <Dropzone
            multiple={false}
            onDrop={(files) => onUpload(files).then(url => setEditOrganizationObject({ ...editOrganizationObject, img_src: url }))}
            onReject={(files) => console.log('rejected files', files)}
            maxSize={3 * 1024 ** 2}
            accept={['image/png', 'image/jpeg', 'image/gif', 'image/webp']}>
            {(status) => dropzoneChildren(<div style={{ width: 200, marginLeft: 'auto', marginRight: 'auto' }}>
            <Image
              src={editOrganizationObject?.img_src}
              imageProps={{ referrerpolicy: "no-referrer" }}
            />
          </div>, status)}
          </Dropzone>
      </InputWrapper>

      <Space h="md" />

      <Textarea
        placeholder="Under 500 words"
        label="Description of the organization"
        autosize
        defaultValue={editOrganizationObject?.description}
        onChange={(e) => setEditOrganizationObject({ ...editOrganizationObject, description: e.target.value })}
      />

      <Space h="md" />


      <InputWrapper
        id="input-demo"
        label="Please select type of services for the organization"
      >
        <MultiSelect
          data={badges?.map(b => ({ label: b.name, value: b.id }))}
          limit={30}
          nothingFound="Nothing found"
          style={{ flex: 1 }}
          sx={(theme) => ({
            backgroundColor: theme.colors.navy[2],
          })}
          defaultValue={editOrganizationObject?.badges?.map(b => b.id)}
          onChange={(b) => setEditOrganizationObject({ ...editOrganizationObject, badges: b })}
        />
      </InputWrapper>

      <Space h="xl" />

      <Button onClick={handleSubmit} variant="gradient" gradient={{ from: 'teal', to: 'lime', deg: 105 }}>{resourcesUpdated && 'Saved' || 'Save'}</Button>

    </Box >
  </div></Container> };
export default OrganizationEditView; 