import useCURDStore from '../hooks/useCURDStore';
import { useState, useEffect } from 'react';
import OrganizationEditView from '../components/organizationEditView';
import { useMatch } from 'react-router-dom';

const OrganizationEdit = () => {
  const { params: { id: editOrganizationId } } = useMatch('admin/organizations/:id/edit');
  const { page,
    totalPage,
    loading,
    resource,
    resources,
    setPage,
    resourcesUpdated,
    listResources,
    getResource,
    createResource,
    updateResource,
    deleteResource, } = useCURDStore({ resourcesName: 'organizations' });
  const {
    resources: badges,
    listResources: getBadges,
  } = useCURDStore({ resourcesName: 'organizations/badges' });
  const [editOrganizationObject, setEditOrganizationObject] = useState({})

  useEffect(() => {
    const fetchEditUser = async () => {
      await getResource(editOrganizationId)
    }
    fetchEditUser()
    getBadges({})
  }, [editOrganizationId])
  useEffect(() => {
    setEditOrganizationObject(resource)
  }, [resource])

  const handleSubmit = () => {
    updateResource(editOrganizationId, {
      name: editOrganizationObject?.name,
      img_src: editOrganizationObject?.img_src,
      description: editOrganizationObject?.description,
      badges: editOrganizationObject?.badges,
    })
  }

  return  <>{!!badges && editOrganizationObject.id && <OrganizationEditView {...{
    editOrganizationObject,
    setEditOrganizationObject,
    resourcesUpdated,
    handleSubmit,
    badges,
  }}/> || <div>loading ...</div> }</>
}

export default OrganizationEdit