import { createGlobalState } from 'react-hooks-global-state';

const { setGlobalState, useGlobalState } = createGlobalState({
  user: {},
});

// export const countUp = () => {
//   setGlobalState('count', (v) => v + 1);
// };

// export const countDown = () => {
//   setGlobalState('count', (v) => v - 1);
// };

export { setGlobalState, useGlobalState };
