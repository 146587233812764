import EventEditView from '../components/eventEditView';
import useCURDStore from '../hooks/useCURDStore';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const UserEdit = () => {
  const navigate = useNavigate();
  const { page,
    totalPage,
    loading,
    resource,
    resources,
    setPage,
    resourcesUpdated,
    listResources,
    getResource,
    createResource,
    updateResource,
    deleteResource, } = useCURDStore({ resourcesName: 'events' });
  const { resources: badges, listResources: listBadges } = useCURDStore({ resourcesName: 'events/badgets' });
  const { resources: organizations, listResources: listOrganizations } = useCURDStore({ resourcesName: 'organizations?all=true&disabled=false' });
  const [editEventObject, setEditEventObject] = useState({
    from_date: new Date(),
    to_date: new Date(),
  })
  useEffect(() => {
    const fetchEditUser = async () => {
      await listBadges({})
      await listOrganizations({})
    }
    fetchEditUser()
  }, [])
  const [editEventDetail, setEditEventDetail] = useState('')
  const [editEventLocation, setEditEventLocation] = useState('')

  const handleEditSubmit = async () => {
    const id = await createResource({
      organization_id: editEventObject?.organization_id,
      name: editEventObject?.name,
      online: editEventObject?.online,
      from_date: editEventObject?.from_date,
      to_date: editEventObject?.to_date,
      description: editEventObject?.description,
      pic_url: editEventObject?.pic_url,
      cms_data: editEventDetail,
      location: editEventLocation,
      time: editEventObject?.time,
      Badges: editEventObject?.Badges,
    })
    navigate(`/admin/events/`);
  }

  return <EventEditView {...{
    badges,
    organizations,
    editEventObject,
    setEditEventObject,
    editEventDetail,
    setEditEventDetail,
    editEventLocation,
    setEditEventLocation,
    handleEditSubmit,
    resourcesUpdated,
  }}/>
}

export default UserEdit