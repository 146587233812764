import { useEffect, useState } from "react";
import useLoginStore from "../hooks/useLoginStore";
import useUserStore from "../hooks/useUserStore";
import { AppShell, Navbar, Header, ScrollArea, Burger, MediaQuery, Text, UnstyledButton, Group, Button, ActionIcon, Box, Avatar, Collapse } from '@mantine/core';
import { Outlet, useNavigate } from 'react-router-dom';
import { BuildingCottage, Users, Ballon, Home as HomeIcon, MoodKid, Tool, Logout } from 'tabler-icons-react';
import '../style.css';
import { NavUser } from "./nav_users";
import MainLinks from "./nav_button";

const links = [
  { icon: <HomeIcon size={32} />, color: 'blue', label: 'Home', href: '/' },
  { icon: <Ballon size={32} />, color: 'teal', label: 'My Events', href: '/my_events' },
  { icon: <Users size={32} />, color: 'grape', label: 'Organizations', href: '/organizations' },
  // { icon: <MoodKid size={32} />, color: 'violet', label: 'Profile', href: '/profile' },
];

const adminLinks = [
  { icon: <Tool size={32} />, color: 'violet', label: 'Admin - Users', href: '/admin/users' },
  { icon: <Tool size={32} />, color: 'violet', label: 'Admin - Events', href: '/admin/events' },
  { icon: <Tool size={32} />, color: 'violet', label: 'Admin - Organizations', href: '/admin/organizations' },
  { icon: <Tool size={32} />, color: 'violet', label: 'Admin - School', href: '/admin/schools' },
]

function Home() {
  const navigate = useNavigate();
  const logout = useLoginStore();
  const [user, fetchUser] = useUserStore();
  const [menuOpened, setMenuOpened] = useState(false);
  const [userHiddenMenuOpened, setUserHiddenMenuOpened] = useState(false);

  return (<AppShell
    navbarOffsetBreakpoint="sm"
    fixed
    padding="0"
    header={<Header hiddenBreakpoint="sm" height={88} p="md"
      sx={(theme) => ({
        backgroundColor: theme.colors.navy[7],
        borderColor: theme.colors.navy[8],
      })}>
      <Box sx={(theme) => ({
        paddingLeft: theme.spacing.xs,
        paddingRight: theme.spacing.xs,
        paddingBottom: theme.spacing.lg,
      })}>
        <Group style={{ cursor: "pointer" }} position="apart">
          <Group onClick={() => navigate('/')}>
            <Box sx={(theme) => ({ radius: theme.radius.xl })}>
              <img style={{ height: 50, borderRadius: '50%' }} src='/tfp_logo.jpg' />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Text size="lg" weight={700} sx={(theme) => ({
                color: theme.colors.blue[0],
                fontSize: 25,
                letterSpacing: 4,
              })}
                variant="gradient"
                gradient={{ from: 'red', to: 'yellow', deg: 45 }}
              >
                FIREWORKS
              </Text>
            </Box>
          </Group>
          <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
            <Burger
              opened={menuOpened}
              onClick={() => setMenuOpened((o) => !o)}
              size="sm"
              color={'#FFF'}
              mr="xl"
            />
          </MediaQuery>
        </Group>
      </Box>
    </Header>}
    navbar={<Navbar hiddenBreakpoint="sm" hidden={!menuOpened} p="xs" width={{ base: 300 }}
      sx={(theme) => ({
        backgroundColor: theme.colors.navy[9],
        borderColor: theme.colors.navy[8],
      })}>

      <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
        {links.map(link => <MainLinks {...link} key={link.label} />)}
        {user?.role?.includes('ADMIN') && adminLinks.map(link => <MainLinks {...link} key={link.label} />)}
        {user?.role?.includes('TEACHER') && <MainLinks icon={<BuildingCottage size={32} />} color="blue" label="My School" href="/teacher" />}
        {user?.role?.includes('ORGANIZATION') && <MainLinks icon={<BuildingCottage size={32} />} color="blue" label="My Organization" href="/organization" />}
      </Navbar.Section>

      <Navbar.Section>
        <NavUser hours={user.hours} firstName={user.first_name} lastName={user.last_name} picture={user.picture} handleOnClick={() => setUserHiddenMenuOpened(!userHiddenMenuOpened)} />
        <Collapse in={userHiddenMenuOpened}>
          <Button
            leftIcon={<MoodKid />}
            color="green"
            size="md"
            radius="md"
            sx={(theme) => ({
              marginTop: theme.spacing.md
            })}
            onClick={() => navigate(`/profile`)}
          >
            Profile
          </Button>
          <Button
            leftIcon={<Logout />}
            color="red"
            size="md"
            radius="md"
            sx={(theme) => ({
              marginBottom: theme.spacing.xs,
              marginTop: theme.spacing.md
            })}
            onClick={() => logout()}
          >
            Logout
          </Button>
        </Collapse>
      </Navbar.Section>
    </Navbar>}
    sx={(theme) => ({
      backgroundColor: theme.colors.navy[6],
    })}
  >
    <ScrollArea sx={(theme) => ({
      padding: `0 ${theme.spacing.md}px`,
      height: 'calc(100vh - 88px)',
    })}>
      <Outlet />
    </ScrollArea>
  </AppShell>)
}

export default Home;
