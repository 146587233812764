import useUserStore from "../hooks/useUserStore";
import StudentsList from './studentsList';
import {
  Title,
  Paper,
  Text,
  Container,
  Select,
  Space,
} from '@mantine/core';
import { useEffect, useState } from "react";
import api from '../api';

const getYears = () => {
  const years = [];
  for (let i = new Date().getFullYear() + 15; i >= 2020; i--) {
    years.push({ label: i, value: ""+i });
  }
  return [{ label: 'All', value: null }, ...years];
}

const SchoolInfo = ({schoolId, schoolName, schoolYear, setSchoolYear}) => {
  const [hours, setHours] = useState(0);
  useEffect(() => {
     api().get(`/schools/${schoolId}${schoolYear? '?school_year='+schoolYear: ''}`).then(res => {
      res.data && setHours(Math.ceil(res.data.total_hours /60));
    })}, [schoolYear]);
  return <Paper shadow="sm" radius="md" p="xl" sx={(theme) => ({
        backgroundColor: theme.colors.white,
      })}><Title>{schoolName}</Title>
    <Space h="sm" />
    <Text>Total Hours: {hours}</Text>
    <Space h="md" />
    <Text>Show by school year: </Text>
    <Select
      placeholder="Pick one"
      data={getYears()}
      value={schoolYear}
      onChange={(e) => setSchoolYear(e)}
    />
  </Paper>
}

const School = ({}) => {
  const [user, fetchUser] = useUserStore();
  const [schoolYear, setSchoolYear] = useState(null);
  if (user.school && user.role_confirmed) {
    return <div>
      <SchoolInfo
        schoolName={user.school.name}
        schoolYear={schoolYear}
        setSchoolYear={setSchoolYear}
        schoolId={user.school.id}
      />
      <StudentsList schoolId={user.school.id} sortList={schoolYear} />
    </div>;
  } else {
    return <Paper shadow="sm" radius="md" p="xl" sx={(theme) => ({
      backgroundColor: theme.colors.navy[2],
    })}>
      <Text>Sorry, your role is not confirmed yet, please contact admin.</Text>
    </Paper>
  }
}

export default (props) => <Container sx={(theme) => ({
  marginTop: theme.spacing.md,
})}><School {...props} /></Container>