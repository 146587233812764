import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function useLoginStore() {
  const navigate = useNavigate();
  const [login, setLogin] = useState({})
  useEffect(() => {
    const login = JSON.parse(localStorage.getItem('login'));
    if (!login) {
      return navigate('/login');
    }
    setLogin(login);
    if (login?.user?.role?.length === 0) {
      navigate('/onboarding');
    }
  }, [])
  function logout() {
    localStorage.removeItem('login');
    navigate('/login');
  }
  return logout;
}
