import { Center, Button, Table, Space, Pagination, Container } from '@mantine/core';
import { Plus, Edit } from 'tabler-icons-react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useCURDStore from '../hooks/useCURDStore';

const AdminListView = (props) => {
  const { page,
    totalPage,
    loading,
    resource,
    resources,
    setPage,
    listResources,
    getResource,
    createResource,
    updateResource,
    deleteResource,
    reloadResources, } = useCURDStore({ resourcesName: props.resources });
  
  const navigate = useNavigate();

  return (
    <Container sx={(theme) => ({
      marginTop: theme.spacing.md,
      maxWidth: 'none',
    })}>
  <div style={{ borderRadius: 3, backgroundColor: '#f6f6f6', padding: 10 }}>

    {
      props.createAllowed &&
      <Button leftIcon={<Plus />} variant="gradient" gradient={{ from: 'teal', to: 'blue', deg: 60 }} onClick={() => navigate('create')}>
        Create
      </Button>
    }

    <Space h="lg" />
    <Table highlightOnHover>
      <thead>
        <tr>
        {props.schema?.map((field, index) => (
          <th key={index}>{field.label}</th>
        ))}
        </tr>
      </thead>
      <tbody>
        {loading ? <tr><td>Loading...</td></tr> :
          resources?.map((resource, index) => {
            return <tr key={index}>
              {(() => {
                let col = []
                props.schema?.forEach((field, index) => {
                  col.push(<td key={index}>
                    { field.display && field.display(resource) || resource[field.name] }
                  </td>)
                })
                if (props.action) {
                  let i = 0;
                  for (let action of props.action) {
                    i ++;
                    col.push(<td key={index + i + 'action'}>{action.component && action.component(resource, reloadResources) || <Button onClick={() => navigate(action.href(resource))} leftIcon={<Edit />} variant="gradient" gradient={{ from: 'teal', to: 'blue', deg: 60 }}>
                      {action.label}
                    </Button>}</td>)
                  }
                }
                return col
              })()}
            </tr>
          })}
      </tbody>
    </Table>

    <Space h="lg" />

    <Center>
      <Pagination total={totalPage} page={page} onChange={(page) => listResources({ _page: page })} color="cyan" radius="md" />
    </Center>

  </div></Container>);
}



export default AdminListView;