import { useMatch } from "react-router-dom";
import { useEffect, useState } from "react";
import AdminListView from "./adminListView";
import { Checkbox } from '@mantine/core';
import api from '../api';

// create a button that can approve or disapprove the student
const ApproveButton = ({
  id,
  defaultChecked,
}) => {
  const [checked, setChecked] = useState(defaultChecked);
  const [loading, setLoading] = useState(false);
  const approve = `/schools/students/${id}/approve`;
  const disapprove = `/schools/students/${id}/disapprove`;

  const onCheck = async () => {
    const url = checked ? disapprove : approve;
    setLoading(true);
    const response = await api().post(url);
    if (response?.data?.success) {
      setChecked(!checked);
    }
    setLoading(false);
  }

  return <div><Checkbox style={{ display: 'inline-block' }} checked={checked} onChange={() => onCheck()} /><span>{loading && '⏳'}</span></div>
}

const Hours = ({ userId }) => {
  const [hours, setHours] = useState(0);

  useEffect(() => {
    api().get(`/users/${userId}/hours`).then(({ data }) => {
      setHours(Math.ceil(data / 60));
    })
  })

  return <div>{hours}</div>
}


const StudentsList = (props) => {
  let school_id = null;
  if (props.schoolId) {
    school_id = props.schoolId;
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { params: { id } } = useMatch(`admin/schools/:id/students`);
    school_id = id;
  }
  const adminStudentSetting = {
    resources: `schools/${school_id}/students${props.sortList ? `?school_year=${props.sortList}` : ''}`,
    schema: [
      { name: 'id', label: 'ID' },
      { name: 'first_name', label: 'First Name' },
      { name: 'last_name', label: 'Last Name' },
      { name: 'student_graduation_year', label: 'Graduation Year' },
      { name: 'student_school_approved', label: 'Approved', display: (o) => <ApproveButton id={o.id} defaultChecked={o.student_school_approved} /> },
      { name: 'hours', label: 'Hours', display: (o) => <Hours userId={o.id} /> },
    ],
    action: [
    ]
  }
  return <AdminListView {...adminStudentSetting} />
}

export default StudentsList