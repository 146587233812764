import useCURDStore from '../hooks/useCURDStore';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import OrganizationEditView from '../components/organizationEditView';

const OrganizationCreate = () => {;
  const navigate = useNavigate();
  const { page,
    totalPage,
    loading,
    resource,
    resources,
    setPage,
    resourcesUpdated,
    listResources,
    getResource,
    createResource,
    updateResource,
    deleteResource, } = useCURDStore({ resourcesName: 'organizations' });
  const {
    resources: badges,
    listResources: getBadges,
  } = useCURDStore({ resourcesName: 'organizations/badges' });

  const [editOrganizationObject, setEditOrganizationObject] = useState({})
  useEffect(() => {
    getBadges({})
  }, [])

  const handleSubmit = async () => {
    const id = await createResource({
        name: editOrganizationObject?.name,
        img_src: editOrganizationObject?.img_src,
        description: editOrganizationObject?.description,
        badges: editOrganizationObject?.badges,
    })
    navigate(`/admin/organizations/`);
  }

  return <>{!!badges && <OrganizationEditView {...{
    editOrganizationObject,
    setEditOrganizationObject,
    resourcesUpdated,
    handleSubmit,
    badges,
  }}/> || <div>loading ...</div> }</>
}

export default OrganizationCreate