import { Button, Group, MediaQuery, MultiSelect, TextInput } from "@mantine/core"
import React from "react";
import { Search } from "tabler-icons-react"

const ServiceTags = [
  { label: 'Education', value: 'Education' },
  { label: 'Community', value: 'Community' },
  { label: 'Social', value: 'Social' },
  { label: 'Medical & Integrated Health', value: 'Medical & Integrated Health' },
  { label: 'Rehabilitation', value: 'Rehabilitation' },
  { label: 'Pre-school', value: 'Pre-school' },
];

const ToolBar = ({
  handleSearch,
  handleServiceTagsFilter,
}) => {
  const [search, setSearch] = React.useState("");

  return <Group
    position="right"
    sx={(theme) => ({
      marginBottom: theme.spacing.md,
    })}
  >
    <MediaQuery
      query="(max-width: 800px)"
      styles={{ width: '100%' }}
    >
      <MultiSelect
        data={ServiceTags}
        limit={30}
        searchable
        nothingFound="Nothing found"
        placeholder="Type of Services"
        style={{ flex: 1 }}
        sx={(theme) => ({
          backgroundColor: theme.colors.navy[2],
        })}
        onChange={handleServiceTagsFilter}
      />
    </MediaQuery>

    <MediaQuery
      query="(max-width: 800px)"
      styles={{ width: '100%' }}
    >
      <TextInput
        placeholder="Search" rightSection={<Button color="blue" size="xxs" onClick={() => handleSearch(search)}> <Search size={14} /> </Button>}
        sx={(theme) => ({
          backgroundColor: theme.colors.navy[2],
        })}
        onChange={(v) => setSearch(v.target.value)}
      />
    </MediaQuery>
  </Group>
}

export default ToolBar