import { Text } from "@mantine/core";
import React from "react";

const Header = (props) => <Text
component="span"
variant="gradient"
gradient={{ from: 'red', to: 'yellow', deg: 45 }}
weight={700}
sx={(theme) => ({
  fontSize: '150%',
  display: 'inline-block',
  marginBottom: theme.spacing.sm
})}
>
{props.children}
</Text>

export default Header;