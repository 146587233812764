import React from 'react';
import { ThemeIcon, UnstyledButton, Group, Text } from '@mantine/core';
import { Link } from "react-router-dom";

export default function MainLink({ icon, color, label, href }) {
  return (
    <UnstyledButton
      component={Link}
      to={href}
      sx={(theme) => ({
        textDecoration: 'none',
        display: 'block',
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color: theme.colors.blue[0],

        '&:hover': {
          backgroundColor:
            theme.colors.blue[9],
        },
      })}
    >
      <Group>
        <ThemeIcon color={color} variant="light">
          {icon}
        </ThemeIcon>

        <Text size="sm">{label}</Text>
      </Group>
    </UnstyledButton>
  );
}