import { useMatch } from "react-router-dom";
import { useEffect, useState } from "react";
import AdminListView from "./adminListView";
import dayjs from 'dayjs';
import { Button, Checkbox, Group, InputWrapper, Input, Text } from '@mantine/core';
import api from '../api';

// create a button that can approve or disapprove the student
const ApprovePanel = ({
  attendee,
}) => {
  const [approved, setApproved] = useState(false);
  const [approvedHours, setApprovedHours] = useState(0);
  const [reviewAt, setReviewedAt] = useState(Date);
  const [loading, setLoading] = useState(false);
  const [updateAllowed, setUpdateAllowed] = useState(false);
  useEffect(() => {
    setApproved(attendee.events[0].approved);
    setApprovedHours(Number(attendee.events[0].approved_hours));
    setReviewedAt(attendee.events[0].reviewed_at);
  }, [])

  const onCheck = () => {
    setUpdateAllowed(true);
    setApproved(!approved);
  }

  const onChangeHours = (v) => {
    setUpdateAllowed(true);
    setApprovedHours(v);
  }


  const onSubmit = async () => {
    setUpdateAllowed(false);
    setLoading(true);
    const response = await api().post(`/events/${attendee.events[0].eventId}/attendees/${attendee.id}/approve`, {
      approved,
      approved_hours: Number(approvedHours),
    });
    if (response?.data?.success) {
      setLoading(false);
    }
  }

  return <Group>
    <InputWrapper
      label="Approve"
    >
      <Checkbox style={{height: 37 }} checked={approved} onChange={onCheck} />
    </InputWrapper>
    <InputWrapper
      label="Minutes"
    >
      <Input value={approvedHours} onChange={(e) => onChangeHours(e.target.value)} />
    </InputWrapper>
    <Button onClick={onSubmit} disabled={!updateAllowed}>Update {loading && '⏳'}</Button>
    <InputWrapper
      label="Reviewed At"
    >
      <Text>{dayjs(reviewAt).format('DD MMM YYYY')}</Text>
    </InputWrapper>
  </Group>
}



const EventAttendances = (props) => {
  const { params: { id: event_id } } = useMatch('admin/events/:id/attendees');
  const adminStudentSetting = {
    resources: `events/${event_id}/attendees`,
    schema: [
      {name: 'id', label: 'ID'},
      { name: 'first_name', label: 'First Name' },
      { name: 'last_name', label: 'Last Name' },
      { name: 'contact', label: 'Contact', display: (o) => <>
        <Text><b>Email:</b> {o.email}</Text>
        <Text><b>Instagram:</b> <a href={`https://instagram.com/${o.instagram}`}>{o.instagram}</a></Text>
      </> },
      { name: 'action', label: 'Attendance', display: (o) => <ApprovePanel attendee={o} /> },
    ],
    action: [
    ]
  }
  return <AdminListView {...adminStudentSetting} />
}

export default EventAttendances